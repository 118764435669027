import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { getArticlesByQuestionSlug } from "services/articles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";

export default function SingleArticle() {
  const { articleSlug } = useParams();
  const { data: article, isLoading } = useQuery({
    queryKey: ["article", articleSlug],
    queryFn: () => getArticlesByQuestionSlug(articleSlug as string),
    enabled: !!articleSlug,
    // refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <div className="w-full bg-white flex flex-col">fetching article...</div>
    );
  }

  if (!article) {
    return (
      <div className="w-full bg-white flex flex-col items-center p-6">
        <h4>Article of slug: {articleSlug} not found.</h4>
        <Link to={"/view-articles"}>Back Home</Link>
      </div>
    );
  }

  const articleContent = article.translation.article
    .map((content) => content.article)
    .join("<br />");

  return (
    <div className="w-full bg-white flex flex-col p-6 gap-8">
      <div className="w-full flex items-end justify-between">
        <h3>{article.translation.question}</h3>
        {article.has_article === 1 ? (
          <div className="flex items-center gap-2">
            <button className="px-6 py-2 flex items-center justify-center text-base font-semibold bg-blue-600 hover:bg-blue-800 text-blue-50 rounded-md">
              Edit article
            </button>
            <button className="px-6 py-2 flex items-center justify-center text-base font-semibold bg-red-600 hover:bg-red-800 text-red-50 rounded-md">
              Delete article
            </button>
          </div>
        ) : (
          <button className="px-6 py-2 flex items-center justify-center text-base font-semibold bg-blue-600 hover:bg-blue-800 text-blue-50 rounded-md">
            Create article
          </button>
        )}
      </div>
      <div className="w-full">
        {article.has_article === 0 ? (
          <p>Question has no article. Create one</p>
        ) : null}
        {/*article.translation.article.map((content) => (
          <ReactQuill theme="snow" value={content.article} key={content.id} />
        ))*/}
        <ReactQuill
          theme="snow"
          value={articleContent}
          onChange={(value) => {
            console.log(value);
          }}
        />
      </div>

      <div className="w-full flex flex-col items-start gap-4">
        <h4>Related Articles</h4>
        <ul className="w-full flex flex-col items-start gap-2 list-disc list-inside">
          {article.translation.topic.questions.map((question) => (
            <li key={question.id}>
              <Link to={`/view-articles/${question.slug}`}>
                {question.question}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
