export const POST_LOGIN = "/auth/login";

// Users
// List View
export const GET_USER_LIST = "/user/all";
export const ADD_USER_LIST = "/user/create";
export const UPDATE_USER_LIST = "/user/update";
export const DELETE_USER_LIST = "/user/delete";

// Churches
// List View
export const GET_CHURCH_LIST = "/church/all";
export const ADD_CHURCH_LIST = "/church/create";
export const UPDATE_CHURCH_LIST = "/church/update";
export const DELETE_CHURCH_LIST = "/church/delete";

//General
export const GET_TIMEZONES = "/timezones";