import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getChurchList as getChurchListApi,
    addChurchList as addChurchListApi,
    updateChurchList as updateChurchListApi,
    deleteChurchList as deleteChurchListApi
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const getChurchList = createAsyncThunk("Churchs/getChurchList", async () => {
    try {
        const response = await getChurchListApi();
        return response.data.churches;
    } catch (error) {
        return error;
    }
});
export const addChurchList = createAsyncThunk("Churchs/addChurchList", async (event: any) => {
    try {
        const response = addChurchListApi(event);
        const data = await response;
        toast.success("Church Added Successfully", { autoClose: 2000 });
        return data;
    } catch (error) {
        toast.error("Church Added Failed", { autoClose: 2000 });
        return error;
    }
});
export const updateChurchList = createAsyncThunk("Churchs/updateChurchList", async (event: any) => {
    try {
        const response = updateChurchListApi(event);
        const data = await response;
        toast.success("Church updated Successfully", { autoClose: 2000 });
        return data;
    } catch (error) {
        toast.error("Church updated Failed", { autoClose: 2000 });
        return error;
    }
});
export const deleteChurchList = createAsyncThunk("Churchs/deleteChurchList", async (event: any) => {
    try {
        const response = deleteChurchListApi(event);
        toast.success("Church deleted Successfully", { autoClose: 2000 });
        return response;
    } catch (error) {
        toast.error("Church deletion Failed", { autoClose: 2000 });
        return error;
    }
});

