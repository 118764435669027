import { apis } from "api";
import AxiosApi from "api/api";
import axios from "axios";

// categories
export const getCategories = async () => {
  return AxiosApi.get<{ data: ArticleCategory[] }>(apis.category.all).then(
    (res) => {
      return res.data.data;
    },
  );
};
export const createCategory = async (data: CategoryData) => {
  return AxiosApi.post<{ message: string }>(apis.category.create, data).then(
    (res) => {
      return res.data.message;
    },
  );
};
export const updateCategory = async (
  data: CategoryData & { category_id: number },
) => {
  return AxiosApi.put<{ message: string }>(apis.category.update, data).then(
    (res) => {
      return res.data.message;
    },
  );
};
export const deleteCategory = async (id: number) => {
  return AxiosApi.delete<{ message: string }>(apis.category.delete(id)).then(
    (res) => {
      return res.data.message;
    },
  );
};
export const uploadCategoryImage = async (data: FormData) => {
  return AxiosApi.post<{ message: string }>(apis.category.uploadImage, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => {
    return res.data.message;
  });
};

// topics
export const getTopicsByCategoryId = async (
  id: ID,
): Promise<ArticleTopic[]> => {
  const res = await axios.get(apis.topic.getByCategory(id));
  const data = await res.data;
  return data;
};
export const addTopic = async (data: TopicData) => {
  return AxiosApi.post<{ message: string }>(apis.topic.create, data).then(
    (res) => {
      return res.data.message;
    },
  );
};
export const updateTopic = async (data: TopicData & { topic_id: number }) => {
  return AxiosApi.put<{ message: string }>(apis.topic.update, data).then(
    (res) => {
      return res.data.message;
    },
  );
};
export const deleteTopic = async (id: number) => {
  return AxiosApi.delete<{ message: string }>(apis.topic.delete(id)).then(
    (res) => {
      return res.data.message;
    },
  );
};

// questions
export const getQuestionsByTopicId = async (id: number) => {
  return AxiosApi.get<{ data: Array<TopicQuestion> }>(
    apis.question.getByTopic(id),
  ).then((res) => {
    return res.data.data;
  });
};

// articles
export const getArticlesByQuestionSlug = async (slug: string) => {
  return AxiosApi.get<{ data: Article }>(apis.article.getBySlug(slug)).then(
    (res) => {
      return res.data.data;
    },
  );
};
