import { useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCategories,
  getQuestionsByTopicId,
  getTopicsByCategoryId,
} from "services/articles";

export default function ArticlesList() {
  const [categoryId, setCategoryId] = useState<ID | null>(null);
  const [topicId, setTopicId] = useState<ID | null>(null);

  const { data: categories } = useQuery({
    queryKey: ["articleCategories"],
    queryFn: getCategories,
    staleTime: Infinity,
  });

  const { data: topics } = useQuery({
    queryKey: ["articles.topics", categoryId],
    queryFn: () => getTopicsByCategoryId(categoryId as number),
    enabled: !!categoryId,
  });

  const { data: questions, isLoading } = useQuery({
    queryKey: ["topics.questions", topicId],
    queryFn: () => getQuestionsByTopicId(topicId as number),
    enabled: !!topicId,
  });

  return (
    <div className="w-full bg-white flex flex-col items-start">
      <div className="w-full flex items-center justify-between px-6 py-4">
        <h5 className="text-15">Article List</h5>
        <button className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2">
          <Plus className="inline-block size-4" />
          Add Article
        </button>
      </div>

      <div className="w-full flex flex-col items-start gap-4 p-4">
        <div className="flex items-start gap-4">
          <select
            onChange={(e) =>
              setCategoryId(+e.target.value !== 0 ? +e.target.value : null)
            }
            className="w-64 p-2 border border-gray-200 bg-gray-100 text-gray-800 rounded disabled:cursor-not-allowed disabled:opacity-50"
          >
            <option value={0}>Select Category</option>
            {categories?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.translation.title}
              </option>
            ))}
          </select>

          <select
            onChange={(e) =>
              setTopicId(+e.target.value !== 0 ? +e.target.value : null)
            }
            className="w-64 p-2 border border-gray-200 bg-gray-100 text-gray-800 rounded disabled:cursor-not-allowed disabled:opacity-50"
            disabled={categoryId === 0 || categoryId === null}
          >
            <option value={0}>Select Topic</option>
            {topics?.map((topic) => (
              <option key={topic.id} value={topic.id}>
                {topic.translation.title}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full flex items-start justify-center">
          {!topicId ? (
            <p>Please select a topic</p>
          ) : isLoading ? (
            <p>Fetching articles questions...</p>
          ) : !questions || questions.length === 0 ? (
            <p>No questions found. Create an article to create a topic</p>
          ) : (
            <div className="w-full flex flex-col items-start divide-y divide-gray-200">
              {questions.map((question) => (
                <SingleQuestion key={question.id} question={question} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SingleQuestion = ({ question }: { question: TopicQuestion }) => {
  const navigate = useNavigate();

  const viewArticle = () => {
    if (question.has_article === 1) {
      navigate(`/view-articles/${question.translation.slug}`);
    }
    return;
  };
  return (
    <Fragment>
      <div className="w-full py-3 flex items-center justify-between">
        <button
          onClick={viewArticle}
          disabled={question.has_article === 0}
          className="disabled:cursor-not-allowed"
        >
          {question.translation.question}
        </button>
      </div>
    </Fragment>
  );
};
