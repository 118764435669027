import { APIClient } from "./api_helper";

import * as url from "./new_url_helper";

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user).user;
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedUser() !== null;
};

// Login Method
export const postLogin = (data: any) => api.create(url.POST_LOGIN, data);

// Users
// List View
export const getUserList = () => api.get(url.GET_USER_LIST, null);
export const addUserList = (data: any) => api.create(url.ADD_USER_LIST, data);
export const updateUserList = (data: any) => api.put(url.UPDATE_USER_LIST, data);
export const deleteUserList = (user: any) => api.delete(url.DELETE_USER_LIST.concat("/", user), { headers: { user } });

// Churches
// List View
export const getChurchList = () => api.get(url.GET_CHURCH_LIST, null);
export const addChurchList = (data: any) => api.create(url.ADD_CHURCH_LIST, data);
export const updateChurchList = (data: any) => api.put(url.UPDATE_CHURCH_LIST, data);
export const deleteChurchList = (church: any) => api.delete(url.DELETE_CHURCH_LIST.concat(`/${church}`), { headers: { church } });

//General
export const getTimezones = () => api.get(url.GET_TIMEZONES, null);