import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import { Dropdown } from "Common/Components/Dropdown";
import TableContainer from "Common/TableContainer";
import Select from "react-select";
import axios from "axios";

// Icons
import {
  Search,
  Trash2,
  Plus,
  MoreHorizontal,
  FileEdit,
  Download,
  SlidersHorizontal,
} from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getUserList as onGetUserList,
  addUserList as onAddUserList,
  updateUserList as onUpdateUserList,
  deleteUserList as onDeleteUserList,
} from "slices/thunk";
import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";

const ListView = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.Users,
    (user) => ({
      userList: user.userList,
    }),
  );

  const { userList } = useSelector(selectDataList);
  // const { timezoneList } = useSelector(selectTimezoneList);
  const [user, setUser] = useState<any>([]);
  const [timezones, setTimezones] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();

  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const url = "https://nl.sharewordglobal.dev/admin/v1";

  useEffect(() => {
    const fetchTimezones = async () => {
      try {
        const response = await axios.get(url.concat(`/timezones`));
        setTimezones(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchTimezones();
  }, []); // Empty array ensures the effect runs only once

  // Get Data
  useEffect(() => {
    dispatch(onGetUserList());
  }, [dispatch]);

  useEffect(() => {
    setUser(userList);
  }, [userList]);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteUserList(eventData.id));
      setDeleteModal(false);
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user_id: (eventData && eventData.id) || "",
      first_name: (eventData && eventData.first_name) || "",
      last_name: (eventData && eventData.last_name) || "",
      username: (eventData && eventData.username) || "",
      email: (eventData && eventData.email) || "",
      person_of_faith: (eventData && eventData.person_of_faith) || "",
      faith: (eventData && eventData.faith) || "",
      preferred_language: (eventData && eventData.preferred_language) || "",
      engagement_level: (eventData && eventData.engagement_level) || "",
      timezone: (eventData && eventData.timezone) || "",
      // password:"",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Username"),
      email: Yup.string().required("Please Enter Email"),
      timezone: Yup.string().required("Please Enter Timezone"),
      faith: Yup.string().required("Please Select Faith"),
      engagement_level: Yup.string().required("Please Select Engagement Level"),
      preferred_language: Yup.string().required(
        "Please Select Preferred Language",
      ),
      person_of_faith: Yup.string().required("Please Select Person of Faith"),
      // password: Yup.string()
      //     .required('No password provided.')
      //     .min(8, 'Password is too short - should be 8 chars minimum.')
      // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateUserList(updateUser));
      } else {
        const newUser = {
          ...values,
        };
        // save new user
        dispatch(onAddUserList(newUser));
      }
      toggle();
    },
  });

  // Image
  const [selectedImage, setSelectedImage] = useState<any>();
  const handleImageChange = (event: any) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        validation.setFieldValue("img", e.target.result);
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  //
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setSelectedImage("");
    } else {
      setShow(true);
      setEventData("");
      setSelectedImage("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["name", "designation", "location", "email", "status"];
    filterDataBySearch(userList, search, keysToSearch, setUser);
  };

  // columns
  // const Status = ({ item }: any) => {
  //     switch (item) {
  //         case "Verified":
  //             return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />{item}</span>);
  //         case "Waiting":
  //             return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"><Loader className="size-3 mr-1.5" />{item}</span>);
  //         case "Rejected":
  //             return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X className="size-3 mr-1.5" />{item}</span>);
  //         default:
  //             return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />{item}</span>);
  //     }
  // };

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "User ID",
        accessorKey: "id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "First Name",
        accessorKey: "first_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell
                .getValue()
                .split(" ")
                .map((word: any) => word.charAt(0))
                .join("")}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.designation}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Last Name",
        accessorKey: "last_name",
        enableColumnFilter: false,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
      },
      {
        header: "Person of Faith",
        accessorKey: "person_of_faith",
        enableColumnFilter: false,
      },
      {
        header: "Faith",
        accessorKey: "faith",
        enableColumnFilter: false,
      },
      {
        header: "Engagement Level",
        accessorKey: "engagement_level",
        enableColumnFilter: false,
      },
      {
        header: "Preferred Language",
        accessorKey: "preferred_language",
        enableColumnFilter: false,
      },
      {
        header: "Timezone",
        accessorKey: "timezone",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              id="usersAction1"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement="right-end"
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="usersAction1"
            >
              <li>
                <Link
                  data-modal-target="addUserModal"
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                  }}
                >
                  <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Edit</span>
                </Link>
              </li>
              <li>
                <Link
                  className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                  to="#!"
                  onClick={() => {
                    const orderData = cell.row.original;
                    onClickDelete(orderData);
                  }}
                >
                  <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Delete</span>
                </Link>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    [],
  );

  const options = [
    { value: "Select Status", label: "Select Status" },
    { value: "Verified", label: "Verified" },
    { value: "Waiting", label: "Waiting" },
    { value: "Rejected", label: "Rejected" },
    { value: "Hidden", label: "Hidden" },
  ];

  const handleChange = (selectedOption: any) => {
    if (
      selectedOption.value === "Select Status" ||
      selectedOption.value === "Hidden"
    ) {
      setUser(userList);
    } else {
      const filteredUsers = userList.filter(
        (data: any) => data.status === selectedOption.value,
      );
      setUser(filteredUsers);
    }
  };

  return (
    <React.Fragment>
      {/* <BreadCrumb title='List View' pageTitle='Users' /> */}
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="usersTable">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Users List</h6>
                <div className="shrink-0">
                  <button
                    type="button"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={toggle}
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Add User</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-2">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for name, email, phone number etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                  <div className="xl:col-span-2">
                    <Select
                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      options={options}
                      isSearchable={false}
                      defaultValue={options[0]}
                      onChange={(event: any) => handleChange(event)}
                      id="choices-single-default"
                    />
                  </div>
                  <div className="xl:col-span-3 xl:col-start-10">
                    <div className="flex gap-2 xl:justify-end">
                      <div>
                        <button
                          type="button"
                          className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                        >
                          <Download className="inline-block size-4" />{" "}
                          <span className="align-middle">Import</span>
                        </button>
                      </div>
                      <button className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20">
                        <SlidersHorizontal className="size-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {user && user.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={user || []}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                  hiddenColumns={[
                    "timezone",
                    "person_of_faith",
                    "preferred_language",
                    "engagement_level",
                  ]}
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 199+ users We did not find any
                      users for you search.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="extraLargeModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen lg:w-[55rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="userNameInput"
                className="inline-block mb-2 text-base font-medium"
              >
                First Name
              </label>
              <input
                type="text"
                id="userNameInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter name"
                name="first_name"
                onChange={validation.handleChange}
                value={validation.values.first_name || ""}
              />
              {validation.touched.first_name && validation.errors.first_name ? (
                <p className="text-red-400">{validation.errors.first_name}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="userNameInput"
                className="inline-block mb-2 text-base font-medium"
              >
                Last Name
              </label>
              <input
                type="text"
                id="userNameInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter name"
                name="last_name"
                onChange={validation.handleChange}
                value={validation.values.last_name || ""}
              />
              {validation.touched.last_name && validation.errors.last_name ? (
                <p className="text-red-400">{validation.errors.last_name}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="designationInput"
                className="inline-block mb-2 text-base font-medium"
              >
                Username
              </label>
              <input
                type="text"
                id="designationInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter username"
                name="username"
                onChange={validation.handleChange}
                value={validation.values.username || ""}
              />
              {validation.touched.username && validation.errors.username ? (
                <p className="text-red-400">{validation.errors.username}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="emailInput"
                className="inline-block mb-2 text-base font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="emailInput"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Enter email"
                name="email"
                onChange={validation.handleChange}
                value={validation.values.email || ""}
              />
              {validation.touched.email && validation.errors.email ? (
                <p className="text-red-400">{validation.errors.email}</p>
              ) : null}
            </div>

            <div className="mb-3">
              <label
                htmlFor="statusSelect"
                className="inline-block mb-2 text-base font-medium"
              >
                Timezone
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                id="statusSelect"
                name="timezone"
                onChange={validation.handleChange}
                value={validation.values.timezone || ""}
              >
                <option value="">Select Timezone</option>
                {timezones?.map((e: any, i: any) => (
                  <option value={e} key={i}>
                    {e}
                  </option>
                ))}
              </select>
              {validation.touched.timezone && validation.errors.timezone ? (
                <p className="text-red-400">{validation.errors.status}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="statusSelect"
                className="inline-block mb-2 text-base font-medium"
              >
                Preferred Language
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                id="statusSelect"
                name="preferred_language"
                onChange={validation.handleChange}
                value={validation.values.preferred_language || ""}
              >
                <option value="">Select Preferred Language</option>
                <option value="english">English</option>
                <option value="french">French</option>
                <option value="spanish">Spanish</option>
              </select>
              {validation.touched.preferred_language &&
                validation.errors.preferred_language ? (
                <p className="text-red-400">{validation.errors.status}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="statusSelect"
                className="inline-block mb-2 text-base font-medium"
              >
                Person of Faith
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                id="statusSelect"
                name="person_of_faith"
                onChange={validation.handleChange}
                value={validation.values.person_of_faith || ""}
              >
                <option value="">Select Person of Faith</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              {validation.touched.person_of_faith &&
                validation.errors.person_of_faith ? (
                <p className="text-red-400">{validation.errors.status}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="statusSelect"
                className="inline-block mb-2 text-base font-medium"
              >
                Faith
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                id="statusSelect"
                name="faith"
                onChange={validation.handleChange}
                value={validation.values.faith || ""}
              >
                <option value="">Select Faith</option>
                <option value="Buddhist">Buddhist</option>
                <option value="Christian">Christian</option>
                <option value="Catholic">Catholic</option>
                <option value="Hindu">Hindu</option>
                <option value="Jew">Jew</option>
                <option value="Muslim">Muslim</option>
                <option value="Other">Other</option>
              </select>
              {validation.touched.faith && validation.errors.faith ? (
                <p className="text-red-400">{validation.errors.status}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="statusSelect"
                className="inline-block mb-2 text-base font-medium"
              >
                Engagement Level
              </label>
              <select
                className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                data-choices
                data-choices-search-false
                id="statusSelect"
                name="engagement_level"
                onChange={validation.handleChange}
                value={validation.values.engagement_level || ""}
              >
                <option value="">Engagement Level</option>
                <option value="0">Undefined</option>
                <option value="1">NonPracticing</option>
                <option value="2">Disillusioned</option>
                <option value="3">InTheMiddle</option>
                <option value="4">Enthusiastic</option>
                <option value="5">ActivelyInvolved</option>
              </select>
              {validation.touched.engagement_level &&
                validation.errors.engagement_level ? (
                <p className="text-red-400">{validation.errors.status}</p>
              ) : null}
            </div>
            {!!isEdit ? (
              ""
            ) : (
              <div className="mb-3">
                <label
                  htmlFor="locationInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="locationInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Password!@"
                  name="password"
                  onChange={validation.handleChange}
                  value={validation.values.password || "Passw0rd!@"}
                  required
                />
                {validation.touched.location && validation.errors.password ? (
                  <p className="text-red-400">{validation.errors.password}</p>
                ) : null}
              </div>
            )}
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update User" : "Add User"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ListView;
