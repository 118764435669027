import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import BreadCrumb from 'Common/BreadCrumb';
import { Link } from 'react-router-dom';
import { Dropdown } from 'Common/Components/Dropdown';
import TableContainer from 'Common/TableContainer';
// import Flatpickr from "react-flatpickr";
// import moment from "moment";
import Select from 'react-select';

// Icons
import { Search, Eye, Trash2, Plus, MoreHorizontal, FileEdit, CheckCircle, Loader, X, Download, SlidersHorizontal, ImagePlus } from 'lucide-react';
import Modal from 'Common/Components/Modal';
import DeleteModal from 'Common/DeleteModal';

// Images
// import dummyImg from "assets/images/users/user-dummy-img.jpg";

// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    getChurchList as onGetChurchList,
    addChurchList as onAddChurchList,
    updateChurchList as onUpdateChurchList,
    deleteChurchList as onDeleteChurchList
} from 'slices/thunk';

import { ToastContainer } from 'react-toastify';
import filterDataBySearch from 'Common/filterDataBySearch';
import HiddenColumns from 'pages/Components/Table/ReactTable/HiddenColumns';

const ListView = () => {
    const dispatch = useDispatch<any>();

    const selectDataList = createSelector(
        (state: any) => state.Churches,
        (Church) => ({
            churchList: Church.ChurchList
        })
    );

    const { churchList } = useSelector(selectDataList);
    const [Church, setChurch] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    // Get Data
    useEffect(() => {
        dispatch(onGetChurchList());
    }, [dispatch]);

    useEffect(() => {
        setChurch(churchList);
    }, [churchList]);

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = () => {
        if (eventData) {
            dispatch(onDeleteChurchList(eventData.id));
            setDeleteModal(false);
        }
    };
    // 

    // Update Data
    const handleUpdateDataClick = (ele: any) => {
        setEventData({ ...ele });
        setIsEdit(true);
        setShow(true);
    };

    // console.log(eventData);
    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // img: (eventData && eventData.img) || '',
            church_id: (eventData && eventData.id) || '',
            // id: (eventData && eventData.id) || '',
            name: (eventData && eventData.name) || '',
            country: (eventData && eventData.country) || '',
            address: (eventData && eventData.address) || '',
            address_2: (eventData && eventData.address_2) || '',
            state: (eventData && eventData.state) || '',
            city: (eventData && eventData.city) || '',
            zip: (eventData && eventData.zip) || '',
            denomination: (eventData && eventData.denomination) || '',
            longitude: (eventData && eventData.longitude) || '',
            latitude: (eventData && eventData.latitude) || '',
            email: (eventData && eventData.email) || '',
            phone: (eventData && eventData.phone) || '',
            url: (eventData && eventData.url) || '',
            contact_name: (eventData && eventData.contact_name) || '',
            service_times: (eventData && eventData.service_times) || '',
            pastor: (eventData && eventData.pastor) || '',
            office_hours: (eventData && eventData.office_hours) || '',
            specialized_ministries: (eventData && eventData.specialized_ministries) || '',
            childrens_ministry: (eventData && eventData.childrens_ministry) || '',
        },
        validationSchema: Yup.object({
            // img: Yup.string().required("Please Add Image"),
            name: Yup.string().required("Please Enter Name"),
            country: Yup.string().required("Please Enter Country"),
            address: Yup.string().required("Please Enter Address"),
            city: Yup.string().required("Please Enter City"),
            denomination: Yup.string().required("Please Enter Denomination"),
            email: Yup.string().required("Please Enter Email"),
            // address_2: Yup.string().required("Please Enter Address 2"),
            // email: Yup.string().required("Please Enter Email"),
        }),

        onSubmit: (values) => {
            if (isEdit) {
                const updateChurch = {
                    id: eventData ? eventData.id : 0,
                    ...values,
                };
                // update Church
                dispatch(onUpdateChurchList(updateChurch));
            } else {
                const newChurch = {
                    ...values,
                    // id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    // ChurchId: "#TW15000" + (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                };
                // save new Church
                dispatch(onAddChurchList(newChurch));
            }
            toggle();
        },
    });

    // Image
    // const [selectedImage, setSelectedImage] = useState<any>();
    // const handleImageChange = (event: any) => {
    //     const fileInput = event.target;
    //     if (fileInput.files && fileInput.files.length > 0) {
    //         const file = fileInput.files[0];
    //         const reader = new FileReader();
    //         reader.onload = (e: any) => {
    //             validation.setFieldValue('img', e.target.result);
    //             setSelectedImage(e.target.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    // 
    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setIsEdit(false);
            // setSelectedImage('');
        } else {
            setShow(true);
            setEventData("");
            // setSelectedImage('');
            validation.resetForm();
        }
    }, [show, validation]);

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['name', 'city', 'denomination', 'address'];
        filterDataBySearch(churchList, search, keysToSearch, setChurch);
    };

    // columns
    const Status = ({ item }: any) => {
        switch (item) {
            case 1:
                return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />Active</span>);
            case "Waiting":
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status"><Loader className="size-3 mr-1.5" />{item}</span>);
            case "Rejected":
                return (<span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status"><X className="size-3 mr-1.5" />{item}</span>);
            default:
                return (<span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status"><CheckCircle className="size-3 mr-1.5" />Inactive</span>);
        }
    };

    const columns = useMemo(() => [
        {
            header: (
                <div className="flex items-center h-full">
                    <input id="CheckboxAll" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
                </div>
            ),
            enableSorting: false,
            id: "checkAll",
            cell: (cell: any) => {
                return (
                    <div className="flex items-center h-full">
                        <input id="Checkbox1" className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer" type="checkbox" />
                    </div>
                );
            }
        },
        {
            header: "Church ID",
            accessorKey: "id",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <Link to="#!" className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 Church-id"
                onClick={() => {
                    const data = cell.row.original;
                    handleUpdateDataClick(data);
                }}
                >{cell.getValue()}</Link>
            ),
        },
        {
            header: "Name",
            accessorKey: "name",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <div className="flex items-center gap-2">
                    <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600"
                    onClick={() => {
                        const data = cell.row.original;
                        handleUpdateDataClick(data);
                    }}>
                        {(cell.getValue().split(' ').map((word: any) => word.charAt(0)).join(''))}
                    </div>
                    <div className="grow">
                        <h6 className="mb-1"><Link to="#!" className="name"
                        onClick={() => {
                            const data = cell.row.original;
                            handleUpdateDataClick(data);
                        }}
                        >{cell.getValue()}</Link></h6>
                        <p className="text-slate-500 dark:text-zink-200">{cell.row.original.designation}</p>
                    </div>
                </div>
            ),
        },
        {
            header: "Country",
            accessorKey: "country",
            enableColumnFilter: false
        },
        {
            header: "State",
            accessorKey: "state",
            enableColumnFilter: false
        },
        {
            header: "City",
            accessorKey: "city",
            enableColumnFilter: false
        },
        {
            header: "Zip Code",
            accessorKey: "zip",
            enableColumnFilter: false
        },
        {
            header: "Address",
            accessorKey: "address",
            enableColumnFilter: false
        },
        {
            header: "Address 2",
            accessorKey: "address_2",
            enableColumnFilter: false
        },
        {
            header: "Denomination",
            accessorKey: "denomination",
            enableColumnFilter: false,
        },
        {
            header: "Longitude",
            accessorKey: "longitude",
            enableColumnFilter: false,
        },
        {
            header: "Latitude",
            accessorKey: "latitude",
            enableColumnFilter: false,
        },
        {
            header: "Phone",
            accessorKey: "phone",
            enableColumnFilter: false,
        },
        {
            header: "Email",
            accessorKey: "email",
            enableColumnFilter: false,
        },
        {
            header: "Website",
            accessorKey: "url",
            enableColumnFilter: false,
        },
        {
            header: "Contact Name",
            accessorKey: "contact_name",
            enableColumnFilter: false,
        },
        {
            header: "Service Times",
            accessorKey: "service_times",
            enableColumnFilter: false,
        },
        {
            header: "Pastor",
            accessorKey: "pastor",
            enableColumnFilter: false,
        },
        {
            header: "Office Hours",
            accessorKey: "office_hours",
            enableColumnFilter: false,
        },
        {
            header: "Specialized Ministries",
            accessorKey: "specialized_ministries",
            enableColumnFilter: false,
        },
        {
            header: "Childrens Ministry",
            accessorKey: "childrens_ministry",
            enableColumnFilter: false,
        },
        {
            header: "Status",
            accessorKey: "status",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <Status item={cell.getValue()} />
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <Dropdown className="relative">
                    <Dropdown.Trigger className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20" id="ChurchsAction1">
                        <MoreHorizontal className="size-3" />
                    </Dropdown.Trigger>
                    <Dropdown.Content placement="right-end" className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600" aria-labelledby="ChurchsAction1">
                        <li>
                            <Link data-modal-target="addChurchModal" className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!"
                                onClick={() => {
                                    const data = cell.row.original;
                                    handleUpdateDataClick(data);
                                }}>
                                <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Edit</span></Link>
                        </li>
                        <li>
                            <Link className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200" to="#!" onClick={() => {
                                const orderData = cell.row.original;
                                onClickDelete(orderData);
                            }}><Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Delete</span></Link>
                        </li>
                    </Dropdown.Content>
                </Dropdown>
            ),
        }
    ], []
    );

    const options = [
        { value: 'Select Status', label: 'Select Status' },
        { value: 'Verified', label: 'Verified' },
        { value: 'Waiting', label: 'Waiting' },
        { value: 'Rejected', label: 'Rejected' },
        { value: 'Hidden', label: 'Hidden' },
    ];

    const handleChange = (selectedOption: any) => {
        if (selectedOption.value === 'Select Status' || selectedOption.value === 'Hidden') {
            setChurch(churchList);
        } else {
            const filteredChurches = churchList.filter((data: any) => data.status === selectedOption.value);
            setChurch(filteredChurches);
        }
    };

    return (
        <React.Fragment>
            {/* <BreadCrumb title='List View' pageTitle='Churches' /> */}
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                <div className="xl:col-span-12">
                    <div className="card" id="ChurchsTable">
                        <div className="card-body">
                            <div className="flex items-center">
                                <h6 className="text-15 grow">Churches List</h6>
                                <div className="shrink-0">
                                    <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}><Plus className="inline-block size-4" /> <span className="align-middle">Add Church</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                            <form action="#!">
                                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                                    <div className="relative xl:col-span-2">
                                        <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for name, email, phone number etc..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                        <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                                    </div>
                                    <div className="xl:col-span-2">
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={options}
                                            isSearchable={false}
                                            defaultValue={options[0]}
                                            onChange={(event: any) => handleChange(event)}
                                            id="choices-single-default"
                                        />
                                    </div>
                                    <div className="xl:col-span-3 xl:col-start-10">
                                        <div className="flex gap-2 xl:justify-end">
                                            <div>
                                                <button type="button" className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"><Download className="inline-block size-4" /> <span className="align-middle">Import</span></button>
                                            </div>
                                            <button className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"><SlidersHorizontal className="size-4" /></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            {Church && Church.length > 0 ?
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(Church || [])}
                                    customPageSize={10}
                                    divclassName="-mx-5 -mb-5 overflow-x-auto"
                                    tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                                    theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                                    tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                                    PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                                    hiddenColumns={[
                                        "address_2",
                                        "phone",
                                        "email",
                                        "childrens_ministry",
                                        "specialized_ministries",
                                        "office_hours",
                                        "timezone",
                                        "url",
                                        "contact_name",
                                        "service_times",
                                        "pastor"
                                    ]}
                                />
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                        <h5 className="mt-2 mb-1">One moment. We're just loading all Church Info.</h5>
                                        <p className="mb-0 text-slate-500 dark:text-zink-200">"... almost done ...</p>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>


            {/* Church Modal  */}
            <Modal show={show} onHide={toggle} id="extraLargeModal" modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen lg:w-[55rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full">
                <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">{!!isEdit ? "Edit Church" : "Add Church"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <form action="#!" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <div className="mb-3">
                            <label htmlFor="ChurchId" className="inline-block mb-2 text-base font-medium">Church ID</label>
                            <input type="text" id="ChurchId" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" disabled
                                name="church_id"
                                value={validation.values.church_id}
                                hidden={!!isEdit ? false : true}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ChurchNameInput" className="inline-block mb-2 text-base font-medium">Name</label>
                            <input type="text" id="ChurchNameInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter name"
                                name="name"
                                onChange={validation.handleChange}
                                value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <p className="text-red-400">{validation.errors.name}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Country</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="country"
                                onChange={validation.handleChange}
                                value={validation.values.country || ""}
                            />
                            {validation.touched.country && validation.errors.country ? (
                                <p className="text-red-400">{validation.errors.country}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">State</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="state"
                                onChange={validation.handleChange}
                                value={validation.values.state || ""}
                            />
                            {validation.touched.state && validation.errors.state ? (
                                <p className="text-red-400">{validation.errors.state}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">City</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="city"
                                onChange={validation.handleChange}
                                value={validation.values.city || ""}
                            />
                            {validation.touched.city && validation.errors.city ? (
                                <p className="text-red-400">{validation.errors.city}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Zip Code</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="zip"
                                onChange={validation.handleChange}
                                value={validation.values.zip || ""}
                            />
                            {validation.touched.zip && validation.errors.zip ? (
                                <p className="text-red-400">{validation.errors.zip}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Address</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="address"
                                onChange={validation.handleChange}
                                value={validation.values.address || ""}
                            />
                            {validation.touched.address && validation.errors.address ? (
                                <p className="text-red-400">{validation.errors.address}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Address 2</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="address_2"
                                onChange={validation.handleChange}
                                value={validation.values.address_2 || ""}
                            />
                            {validation.touched.address_2 && validation.errors.address_2 ? (
                                <p className="text-red-400">{validation.errors.address_2}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Denomination</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="denomination"
                                onChange={validation.handleChange}
                                value={validation.values.denomination || ""}
                            />
                            {validation.touched.denomination && validation.errors.denomination ? (
                                <p className="text-red-400">{validation.errors.denomination}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Longitude</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="longitude"
                                onChange={validation.handleChange}
                                value={validation.values.longitude || ""}
                            />
                            {validation.touched.longitude && validation.errors.longitude ? (
                                <p className="text-red-400">{validation.errors.longitude}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="designationInput" className="inline-block mb-2 text-base font-medium">Latitude</label>
                            <input type="text" id="designationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter designation"
                                name="latitude"
                                onChange={validation.handleChange}
                                value={validation.values.latitude || ""}
                            />
                            {validation.touched.latitude && validation.errors.latitude ? (
                                <p className="text-red-400">{validation.errors.latitude}</p>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="emailInput" className="inline-block mb-2 text-base font-medium">Email</label>
                            <input type="email" id="emailInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Enter email"
                                name="email"
                                onChange={validation.handleChange}
                                value={validation.values.email || ""}
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <p className="text-red-400">{validation.errors.email}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phoneNumberInput" className="inline-block mb-2 text-base font-medium">Phone Number</label>
                            <input type="tel" id="phoneNumberInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="12345 67890"
                                name="phone"
                                onChange={validation.handleChange}
                                value={validation.values.phone || ""}
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <p className="text-red-400">{validation.errors.phone}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="urlInput" className="inline-block mb-2 text-base font-medium">Website</label>
                            <input type="url" id="urlInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="12345 67890"
                                name="url"
                                onChange={validation.handleChange}
                                value={validation.values.url || ""}
                            />
                            {validation.touched.url && validation.errors.url ? (
                                <p className="text-red-400">{validation.errors.url}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="contactNameInput" className="inline-block mb-2 text-base font-medium">Contact Name</label>
                            <input type="text" id="contactNameInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="12345 67890"
                                name="url"
                                onChange={validation.handleChange}
                                value={validation.values.contact_name || ""}
                            />
                            {validation.touched.contact_name && validation.errors.contact_name ? (
                                <p className="text-red-400">{validation.errors.contact_name}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="statusSelect" className="inline-block mb-2 text-base font-medium">Childrens Ministry</label>
                            <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false id="statusSelect"
                                name="childrens_ministry"
                                onChange={validation.handleChange}
                                value={validation.values.childrens_ministry || ""}
                            >
                                <option value="">Select Childrens Ministry</option>
                                <option value="yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {validation.touched.childrens_ministry && validation.errors.childrens_ministry ? (
                                <p className="text-red-400">{validation.errors.childrens_ministry}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="locationInput" className="inline-block mb-2 text-base font-medium">Service Times</label>
                            <input type="text" id="locationInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Location"
                                name="service_times"
                                onChange={validation.handleChange}
                                value={validation.values.service_times || ""}
                            />
                            {validation.touched.service_times && validation.errors.service_times ? (
                                <p className="text-red-400">{validation.errors.service_times}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="pastorInput" className="inline-block mb-2 text-base font-medium">Pastor</label>
                            <input type="text" id="pastorInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Location"
                                name="pastor"
                                onChange={validation.handleChange}
                                value={validation.values.pastor || ""}
                            />
                            {validation.touched.pastor && validation.errors.pastor ? (
                                <p className="text-red-400">{validation.errors.pastor}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="officeHoursInput" className="inline-block mb-2 text-base font-medium">Office Hours</label>
                            <input type="text" id="officeHoursInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Location"
                                name="office_hours"
                                onChange={validation.handleChange}
                                value={validation.values.office_hours || ""}
                            />
                            {validation.touched.office_hours && validation.errors.office_hours ? (
                                <p className="text-red-400">{validation.errors.office_hours}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="specializedMinistriesInput" className="inline-block mb-2 text-base font-medium">Specialized Ministries</label>
                            <input type="text" id="specializedMinistriesInput" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Location"
                                name="specialized_ministries"
                                onChange={validation.handleChange}
                                value={validation.values.specialized_ministries || ""}
                            />
                            {validation.touched.specialized_ministries && validation.errors.specialized_ministries ? (
                                <p className="text-red-400">{validation.errors.specialized_ministries}</p>
                            ) : null}
                        </div>
                        <div className="flex justify-end gap-2 mt-4">
                            <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={toggle}>Cancel</button>
                            <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update Church" : "Add Church"}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default ListView;