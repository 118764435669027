import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { TextInput } from "Common/Components/form/input";
import { Modal } from "Common/Components/modal-2";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { addTopic, getCategories, updateTopic } from "services/articles";

export const AddEditTopic = ({
  open,
  close,
  topic,
  category_id,
}: {
  open: boolean;
  close: () => void;
  topic?: ArticleTopic;
  category_id?: number;
}) => {
  console.log(topic);
  const [categoryId, setCategoryId] = useState<ID | null>(category_id || null);
  const [error, setError] = useState<string | null>(null);
  // fetch categories
  const { data: categories } = useQuery({
    queryKey: ["articleCategories"],
    queryFn: getCategories,
    staleTime: Infinity,
  });
  // queryClient
  const queryClient = useQueryClient();
  const addTopicAction = useMutation({
    mutationFn: addTopic,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["articleTopics", categoryId],
      });
      toast.success(res, { autoClose: 2000 });
      close();
    },
  });
  const updateTopicAction = useMutation({
    mutationFn: updateTopic,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["articleTopics", categoryId],
      });
      toast.success(res, { autoClose: 2000 });
      close();
    },
  });

  const handleAddEditTopic = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!categoryId) {
      setError("Please select a category");
      return;
    }
    setError(null);

    const formData = new FormData(event.currentTarget);
    const en_title = (formData.get("en_title") as string) || "";
    const fr_title = (formData.get("fr_title") as string) || "";
    const es_title = (formData.get("es_title") as string) || "";
    const en_short_title = (formData.get("en_short_title") as string) || "";
    const fr_short_title = (formData.get("fr_short_title") as string) || "";
    const es_short_title = (formData.get("es_short_title") as string) || "";

    const topicData: TopicData = {
      category_id: categoryId,
      content: [
        {
          language_code: "en",
          short_title: en_short_title,
          title: en_title,
        },
        {
          language_code: "fr",
          short_title: fr_short_title,
          title: fr_title,
        },
        {
          language_code: "es",
          short_title: es_short_title,
          title: es_title,
        },
      ],
    };

    if (!topic) {
      await addTopicAction.mutateAsync(topicData);
    } else {
      await updateTopicAction.mutateAsync({
        ...topicData,
        topic_id: topic.id,
      });
    }
  };

  const frTitle = topic?.translations.find(
    (title) => title.language_code === "fr",
  )?.title;
  const esTitle = topic?.translations.find(
    (title) => title.language_code === "es",
  )?.title;
  const frShortTitle = topic?.translations.find(
    (title) => title.language_code === "fr",
  )?.short_title;
  const esShortTitle = topic?.translations.find(
    (title) => title.language_code === "es",
  )?.short_title;

  return (
    <Modal open={open} close={close}>
      <Modal.Header>
        <Modal.Title>{topic ? "Edit" : "Add"} Topic</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleAddEditTopic}
          className="w-full flex flex-col items-start gap-4"
        >
          <select
            onChange={(e) =>
              setCategoryId(+e.target.value !== 0 ? +e.target.value : null)
            }
            className="w-1/2 p-2 border border-gray-200 bg-gray-100 text-gray-800 rounded disabled:cursor-not-allowed disabled:opacity-50"
            disabled={topic ? true : false}
          >
            <option value={0}>Select Category</option>
            {categories?.map((category) => (
              <option
                key={category.id}
                value={category.id}
                selected={category.id === category_id}
              >
                {category.translation.title}
              </option>
            ))}
          </select>

          <div className="w-full grid grid-cols-2 gap-4 items-start">
            <TextInput
              name="en_title"
              label="Title (English)"
              placeholder="Enter title in English"
              defaultValue={topic?.translation.title}
            />
            <TextInput
              name="en_short_title"
              label="Short Title (English)"
              placeholder="Enter title in English"
              defaultValue={topic?.translation.short_title}
            />
          </div>
          <div className="w-full grid grid-cols-2 gap-4 items-start">
            <TextInput
              name="fr_title"
              label="Title (French)"
              placeholder="Enter title in French"
              defaultValue={frTitle}
            />
            <TextInput
              name="fr_short_title"
              label="Short Title (French)"
              placeholder="Enter title in French"
              defaultValue={frShortTitle}
            />
          </div>
          <div className="w-full grid grid-cols-2 gap-4 items-start">
            <TextInput
              name="es_title"
              label="Title (Espansol)"
              placeholder="Enter title in English"
              defaultValue={esTitle}
            />
            <TextInput
              name="es_short_title"
              label="Short Title (Espansol)"
              placeholder="Enter title in English"
              defaultValue={esShortTitle}
            />
          </div>

          {error ? (
            <p className="text-xs text-red-800 font-semibold">{error}</p>
          ) : null}
          <div className="w-full flex justify-end items-start gap-2">
            <button
              onClick={close}
              className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800"
            >
              {topic ? "Save topic" : "Add topic"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
