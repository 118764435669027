import { createSlice } from "@reduxjs/toolkit";
import {
    getChurchList,
    addChurchList,
    updateChurchList,
    deleteChurchList,
} from './thunk';

export const initialState = {
    ChurchList: [],
    ChurchGrid: [],
    errors: {}
};

const ChurchSlice = createSlice({
    name: 'Church',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // List
        builder.addCase(getChurchList.fulfilled, (state: any, action: any) => {
            state.ChurchList = action.payload;
        });
        builder.addCase(getChurchList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addChurchList.fulfilled, (state: any, action: any) => {
            state.ChurchList.unshift(action.payload);
        });
        builder.addCase(addChurchList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(updateChurchList.fulfilled, (state: any, action: any) => {
            state.ChurchList = state.ChurchList.map((list: any) =>
                list.id === action.payload.id
                    ? { ...list, ...action.payload }
                    : list
            );
        });
        builder.addCase(updateChurchList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(deleteChurchList.fulfilled, (state: any, action: any) => {
            state.ChurchList = state.ChurchList.filter(
                (ChurchList: any) => ChurchList.id.toString() !== action.payload.toString()
            );
        });
        builder.addCase(deleteChurchList.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
});

export default ChurchSlice.reducer;