import { useMutation } from "@tanstack/react-query";
import { ImageInput } from "Common/Components/form/image";
import { Modal } from "Common/Components/modal-2";
import { FormEvent } from "react";
import { uploadCategoryImage } from "services/articles";

export const UpdateCategoryImage = ({
  open,
  close,
  category_id,
  categoryTitle,
}: {
  open: boolean;
  close: () => void;
  category_id: number;
  categoryTitle: string;
}) => {
  const uploadImagesAction = useMutation({
    mutationFn: uploadCategoryImage,
  });
  const handleUploadImages = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append("category_id", category_id.toString());

    await uploadImagesAction.mutateAsync(formData);
    close();
  };

  return (
    <Modal open={open} close={close}>
      <Modal.Header>
        <Modal.Title>Upload Images for "{categoryTitle}" category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleUploadImages}
          className="w-full flex flex-col gap-4 items-start"
        >
          <ImageInput name="thumbnail_image" label="Thumbnail" />
          <ImageInput name="full_image" label="Full Image" />
          <div className="w-full flex justify-end items-start gap-2">
            <button
              onClick={close}
              className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800"
            >
              Cancel
            </button>
            <button
              className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800 disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
              disabled={uploadImagesAction.isPending}
            >
              Upload images
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
