import { ChangeEvent, ComponentProps, useState } from "react";

export const ImageInput = ({
  name,
  label,
  ...props
}: ComponentProps<"input"> & { label: string }) => {
  const [fileName, setFileName] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      // Update the fileName state with the selected file's name
      setFileName(event.target.files[0].name);
    }
  };
  return (
    <div className="w-full flex flex-col gap-1.5">
      <p className="text-xs font-semibold text-gray-600">{label}</p>
      <div className="w-full border border-dashed border-gray-200 rounded h-24 flex flex-col items-center justify-center gap-2">
        <p className="text-xs leading-[14px] text-gray-700 font-medium">
          Drag & drop or{" "}
          <label
            htmlFor={name}
            className="font-bold text-blue-700 cursor-pointer"
          >
            Choose file
          </label>{" "}
          to upload
        </p>
        <span className="text-xs leading-[14px] text-gray-400">
          {fileName ? fileName : "jpg. jpeg. png. files supported"}
        </span>
        <input
          id={name}
          type="file"
          accept="image/*"
          name={name}
          className="hidden"
          onChange={handleImageChange}
          /*   className="w-full border border-gray-200 p-2 rounded text-sm placeholder:text-xs focus:outline-none focus:border-gray-800" */
          {...props}
        />
      </div>
    </div>
  );
};
