import { baseUrl } from "api";
import axios from "axios";

const AxiosApi = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
  },
});

const authUser: any = localStorage.getItem("authUser");
const token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;

AxiosApi.interceptors.request.use(function(config) {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default AxiosApi;
