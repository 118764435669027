import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal } from "Common/Components/modal-2";
import { deleteTopic } from "services/articles";

export const DeleteTopic = ({
  open,
  close,
  id,
  topicTitle,
  categoryId,
}: {
  open: boolean;
  close: () => void;
  id: number;
  topicTitle: string;
  categoryId: number;
}) => {
  const queryClient = useQueryClient();
  const deleteTopicAction = useMutation({
    mutationFn: deleteTopic,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["articleTopics", categoryId],
      });

      close();
    },
  });

  return (
    <Modal open={open} close={close}>
      <Modal.Header>
        <Modal.Title>Delete {topicTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete "{topicTitle}"?</p>
        <div className="w-full flex justify-end items-start gap-2">
          <button
            onClick={close}
            className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800"
          >
            No, cancel
          </button>
          <button
            className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={async () => {
              await deleteTopicAction.mutateAsync(id);
            }}
            disabled={deleteTopicAction.isPending}
          >
            Yes, delete
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
