import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal } from "Common/Components/modal-2";
import { toast } from "react-toastify";
import { deleteCategory } from "services/articles";

export const DeleteCategory = ({
  open,
  close,
  id,
  categoryTitle,
}: {
  open: boolean;
  close: () => void;
  id: number;
  categoryTitle: string;
}) => {
  const queryClient = useQueryClient();
  const deleteCategoryAction = useMutation({
    mutationFn: deleteCategory,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["articleCategories"] });
      toast.success(res, { autoClose: 2000 });
    },
  });
  return (
    <Modal open={open} close={close}>
      <Modal.Header>
        <Modal.Title>Delete {categoryTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete "{categoryTitle}"?</p>
        <div className="w-full flex justify-end items-start gap-2">
          <button
            onClick={close}
            className="p-2 px-4 rounded text-white font-semibold text-base bg-blue-500 hover:bg-blue-800"
          >
            No, cancel
          </button>
          <button
            className="p-2 px-4 rounded text-white font-semibold text-base bg-red-500 hover:bg-red-800 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={async () => {
              await deleteCategoryAction.mutateAsync(id);
            }}
            disabled={deleteCategoryAction.isPending}
          >
            Yes, delete
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
